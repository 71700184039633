import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Image, Form, Modal } from 'react-bootstrap'
import BarcodeReader from 'react-barcode-reader'
import { BiImport } from 'react-icons/bi'
import { logoutPapa } from '../Actions/userActions'

import { checkItem } from '../Actions/importActions'
import { CHECK_RESET } from '../Constants/importConstants'

import Logo from '../images/logo.png'
import Loading from './Loading'

const ItemChecker = ({ history }) => {
	const [sku, setSku] = useState('')
	const [show, setShow] = useState(false)

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const papaLogin = useSelector((state) => state.papaLogin)
	const { papaInfo } = papaLogin

	const checkItems = useSelector((state) => state.checkItems)
	const { loading, checkData, checkError } = checkItems

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (checkData) {
			setShow(true)
		}

		if (checkError) {
			toast.error(checkError)
			dispatch({ type: CHECK_RESET })
		}
	}, [userInfo, checkData, checkError, history, dispatch])

	const importBarcode = async (data) => {
		if (!sku) {
			console.log({ Barcode: data })
			await runCheck(data)
		} else {
			console.log({ Form: sku })
			await runCheck(sku.toUpperCase())
			setSku('')
		}
	}

	const handleClose = () => {
		setShow(false)
		dispatch({ type: CHECK_RESET })
	}

	const runCheck = async (barcode) => {
		var slug = ''
		for (var pair of papaInfo.pairs) {
			if (pair.sku === barcode) {
				slug = pair.prod_slug
			}
		}

		await dispatch(
			checkItem(
				userInfo.userToken,
				userInfo.apiToken,
				slug,
				barcode,
				papaInfo.session.sessionCookie
			)
		)
	}

	const handleLogout = () => {
		dispatch(logoutPapa())
	}
	return (
		<Container>
			<ToastContainer />
			<Row style={{ justifyContent: 'center' }}>
				<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
			</Row>
			{loading ? (
				<Loading />
			) : (
				<>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>
								{checkData ? checkData.name : 'Undefined'}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<img
								className="d-block w-100"
								src={checkData ? checkData.image1 : '#'}
								alt="#"
							/>
						</Modal.Body>
						<Modal.Footer>
							<Link
								to="#"
								className="btn btn-primary float-right"
								onClick={handleClose}
							>
								Close
							</Link>
						</Modal.Footer>
					</Modal>
					<Row style={{ justifyContent: 'center' }}>
						<Card style={{ width: '80%' }}>
							<Card.Body>
								<Card.Title style={{ textAlign: 'center' }}>
									<h1>
										<BiImport style={{ marginRight: 10 }} />
										Check Items
									</h1>
								</Card.Title>
								<span className="input-group-text" style={{ marginTop: 75 }}>
									Click/Tap Here to start scanning...
								</span>
								<BarcodeReader onScan={importBarcode} />

								<Form style={{ paddingTop: 10 }}>
									<Form.Group controlId="skuForm">
										<Form.Control
											type="string"
											placeholder="Enter Product SKU"
											onChange={(e) => setSku(e.target.value)}
										/>
									</Form.Group>

									<Link
										to="#"
										className="btn btn-primary"
										onClick={importBarcode}
									>
										Check For Item
									</Link>
									<Link
										to="#"
										className="btn btn-primary float-right"
										onClick={handleLogout}
									>
										Logout of Paparazzi
									</Link>
								</Form>
							</Card.Body>
						</Card>
					</Row>
				</>
			)}
		</Container>
	)
}

export default ItemChecker
