import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Image } from 'react-bootstrap'
import { BiImport } from 'react-icons/bi'
import { CSVReader } from 'react-papaparse'
import { logoutPapa } from '../Actions/userActions'

import { importItem } from '../Actions/importActions'
import { IMPORT_RESET } from '../Constants/importConstants'

import Logo from '../images/logo.png'
import ImportLoading from './ImportLoading'

const CsvImporter = ({ history }) => {
	const [importingCSV, setImportingCSV] = useState(false)

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const papaLogin = useSelector((state) => state.papaLogin)
	const { papaInfo } = papaLogin

	const importItems = useSelector((state) => state.importItems)
	const { importMessage, importError } = importItems

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (importMessage) {
			toast.success(importMessage.message)
			dispatch({ type: IMPORT_RESET })
		}

		if (importError) {
			toast.error(importError)
			dispatch({ type: IMPORT_RESET })
		}
	}, [userInfo, importMessage, importError, history, dispatch])

	/**======================
	 *    Add CSV Upload and Import
	 *========================**/

	var handleOnDrop = async (data) => {
		setImportingCSV(true)
		console.log('---------------------------')
		for (var item of data) {
			/* console.log('SKU: ' + item.data[1] + ' Quantity: ' + item.data[0]) */
			await runImport(item.data[1], item.data[0])
		}
		console.log('---------------------------')
		setImportingCSV(false)
	}

	var handleOnError = (err, file, inputElem, reason) => {
		console.log(err)
	}

	var handleOnRemoveFile = (data) => {
		console.log('---------------------------')
		console.log(data)
		console.log('---------------------------')
	}

	const runImport = async (barcode, qty) => {
		var slug = ''
		for (var pair of papaInfo.pairs) {
			if (pair.sku === barcode) {
				slug = pair.prod_slug
			}
		}

		await dispatch(
			importItem(
				userInfo.userToken,
				userInfo.apiToken,
				slug,
				barcode,
				papaInfo.session.sessionCookie,
				qty
			)
		)
	}

	const handleLogout = () => {
		dispatch(logoutPapa())
	}
	return (
		<>
			{importingCSV && <ImportLoading />}
			<Container>
				<ToastContainer autoClose={1000} />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				<>
					<Row style={{ justifyContent: 'center' }}>
						<Card style={{ width: '80%' }}>
							<Card.Body>
								<Card.Title style={{ textAlign: 'center' }}>
									<h1>
										<BiImport style={{ marginRight: 10 }} />
										Import Items
									</h1>
								</Card.Title>
								<CSVReader
									style={{
										dropArea: {
											width: '100%',
											zIndex: '0',
										},
									}}
									onDrop={handleOnDrop}
									onError={handleOnError}
									addRemoveButton
									onRemoveFile={handleOnRemoveFile}
								>
									<span>Drop CSV file here or click to upload.</span>
								</CSVReader>

								<Link
									to="#"
									className="btn btn-primary float-right"
									onClick={handleLogout}
									style={{ marginTop: 25 }}
								>
									Logout of Paparazzi
								</Link>
							</Card.Body>
						</Card>
					</Row>
				</>
			</Container>
		</>
	)
}

export default CsvImporter
