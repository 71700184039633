export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const PAPA_LOGIN_REQUEST = 'PAPA_LOGIN_REQUEST'
export const PAPA_LOGIN_SUCCESS = 'PAPA_LOGIN_SUCCESS'
export const PAPA_LOGIN_FAIL = 'PAPA_LOGIN_FAIL'
export const PAPA_LOGOUT = 'PAPA_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'
