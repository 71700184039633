export const ADMIN_LIST_USERS_REQUEST = 'ADMIN_LIST_USERS_REQUEST'
export const ADMIN_LIST_USERS_SUCCESS = 'ADMIN_LIST_USERS_SUCCESS'
export const ADMIN_LIST_USERS_FAIL = 'ADMIN_LIST_USERS_FAIL'
export const ADMIN_LIST_USERS_RESET = 'ADMIN_LIST_USERS_RESET'

export const ADMIN_DELETE_USER_REQUEST = 'ADMIN_DELETE_USER_REQUEST'
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN_DELETE_USER_SUCCESS'
export const ADMIN_DELETE_USER_FAIL = 'ADMIN_DELETE_USER_FAIL'
export const ADMIN_DELETE_USER_RESET = 'ADMIN_DELETE_USER_RESET'

export const ADMIN_EDIT_USER_REQUEST = 'ADMIN_EDIT_USER_REQUEST'
export const ADMIN_EDIT_USER_SUCCESS = 'ADMIN_EDIT_USER_SUCCESS'
export const ADMIN_EDIT_USER_FAIL = 'ADMIN_EDIT_USER_FAIL'
export const ADMIN_EDIT_USER_RESET = 'ADMIN_EDIT_USER_RESET'

export const ADMIN_GET_USER_REQUEST = 'ADMIN_GET_USER_REQUEST'
export const ADMIN_GET_USER_SUCCESS = 'ADMIN_GET_USER_SUCCESS'
export const ADMIN_GET_USER_FAIL = 'ADMIN_GET_USER_FAIL'
export const ADMIN_GET_USER_RESET = 'ADMIN_GET_USER_RESET'
