import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

//Bring in components
import Login from './Screens/Login'
import Dashboard from './Screens/Dashboard'
import ImportItems from './Screens/ImportItems'
import RemoveItems from './Screens/RemoveItems'
import AddUsers from './Screens/AddUsers'
import ManageUsers from './Screens/ManageUsers'
import EditUser from './Screens/EditUser'
import ImportCSV from './Screens/ImportCSV'

//Bring in guards
import RouteGuard from './Guards/RouteGuard'
import CheckItems from './Screens/CheckItem'
import CheckSiteItems from './Screens/CheckSiteItem'
import ShowItemOnSite from './Screens/ShowItemOnSite'

function App() {
	return (
		<>
			<Router>
				<Route path="/" exact component={Login} />
				<Route path="/dashboard" exact component={Dashboard} />
				<Route path="/import-items" exact component={ImportItems} />
				<Route path="/check-items" exact component={CheckItems} />
				<Route path="/check-site-items" exact component={CheckSiteItems} />
				<Route path="/show-site-item" exact component={ShowItemOnSite} />
				<Route path="/import-csv" exact component={ImportCSV} />
				<Route path="/remove-items" exact component={RemoveItems} />
				<RouteGuard path="/admin/register" exact component={AddUsers} />
				<RouteGuard path="/admin/manage-users" exact component={ManageUsers} />
				<RouteGuard path="/admin/edit-user/:id" exact component={EditUser} />
			</Router>
		</>
	)
}

export default App
