import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Form, Image, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ImUserPlus } from 'react-icons/im'

import Logo from '../images/logo.png'
import Loading from '../Components/Loading'
import Header from '../Components/Header'
import { addNewUser } from '../Actions/userActions'
import { USER_REGISTER_RESET } from '../Constants/userConstants'

const AddUsers = ({ history }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [password2, setPassword2] = useState('')
	const [isAdmin, setIsAdmin] = useState('false')
	const [userService, setUserService] = useState('Paparazzi')
	const [apiURL, setApiURL] = useState('')
	const [userKey, setUserKey] = useState('')
	const [authKey, setAuthKey] = useState('')
	const [apiType, setApiType] = useState('YUP')

	const dispatch = useDispatch()

	const registerUser = useSelector((state) => state.registerUser)
	const { loading, registerMessage, registerError } = registerUser

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (registerMessage) {
			toast.success(registerMessage.message)
			dispatch({ type: USER_REGISTER_RESET })
		}

		if (registerError) {
			console.log(registerError)
			toast.error(registerError)
			dispatch({ type: USER_REGISTER_RESET })
		}
	}, [history, dispatch, userInfo, registerMessage, registerError])

	const handleRegister = (e) => {
		e.preventDefault()
		if (
			!name ||
			!email ||
			!password ||
			!password2 ||
			!isAdmin ||
			!userService ||
			!apiURL ||
			!userKey ||
			!authKey ||
			!apiType
		) {
			toast.error('You must complete all fields!')
		} else if (password !== password2) {
			toast.error('Passwords do not match!')
		} else if (password.length < 8) {
			toast.error('Password must be at least 8 characters!')
		} else {
			dispatch(
				addNewUser(
					userInfo.userToken,
					name,
					email,
					password,
					password2,
					isAdmin,
					userService,
					userKey,
					authKey,
					apiURL,
					apiType
				)
			)
		}
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading ? (
					<Loading />
				) : (
					<>
						<Row style={{ justifyContent: 'center' }}>
							<Card style={{ width: '80%' }}>
								<Card.Body>
									<Card.Title style={{ textAlign: 'center' }}>
										<h1>
											<ImUserPlus style={{ marginRight: 10 }} />
											Add User
										</h1>
									</Card.Title>

									<Form style={{ paddingTop: 10 }} onSubmit={handleRegister}>
										<Form.Group controlId="frmName">
											<Form.Label>Full Name</Form.Label>
											<Form.Control
												type="string"
												placeholder="Enter Full Name"
												onChange={(e) => setName(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmEmail">
											<Form.Label>Eamil Address</Form.Label>
											<Form.Control
												type="email"
												placeholder="Enter Email Address"
												onChange={(e) => setEmail(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmPassword">
											<Form.Label>Password</Form.Label>
											<Form.Control
												type="password"
												placeholder="Password"
												onChange={(e) => setPassword(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmPassword2">
											<Form.Label>Confirm Password</Form.Label>
											<Form.Control
												type="password"
												placeholder="Re-Enter Password"
												onChange={(e) => setPassword2(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmIsAdmin">
											<Form.Label>Is User an Admin?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={'false'}
												onChange={(e) => setIsAdmin(e.target.value)}
											>
												<option value="true">True</option>
												<option value="false">False</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmUserService">
											<Form.Label>Service?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={'Paparazzi'}
												onChange={(e) => setUserService(e.target.value)}
											>
												<option value="none">None</option>
												<option value="Paparazzi">Paparazzi</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmApiType">
											<Form.Label>API Type?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={'YUP'}
												onChange={(e) => setApiType(e.target.value)}
											>
												<option value="none">None</option>
												<option value="woocommerce">WooCommerce</option>
												<option value="YUP">YUP! Commerce</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmApiURL">
											<Form.Label>API URL?</Form.Label>
											<Form.Control
												type="string"
												placeholder="Enter API URL"
												onChange={(e) => setApiURL(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmUserKey">
											<Form.Label>API User Key</Form.Label>
											<Form.Control
												type="string"
												placeholder="Enter API User Key"
												onChange={(e) => setUserKey(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmAuthKey">
											<Form.Label>API Authentication Key</Form.Label>
											<Form.Control
												type="string"
												placeholder="Enter API Auth Key"
												onChange={(e) => setAuthKey(e.target.value)}
											/>
										</Form.Group>

										<Button variant="primary" type="submit" className="mb-2">
											Add User
										</Button>
										<br></br>
										<Link to="/">Return to Dashboard</Link>
									</Form>
								</Card.Body>
							</Card>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default AddUsers
