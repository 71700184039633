import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../Components/Loading'

const RouteGuard = ({ component: Component, ...rest }) => {
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, userInfo } = userLogin

	return (
		<Route
			{...rest}
			render={(props) =>
				!loading ? (
					userInfo && userInfo.isAdmin ? (
						<Component {...props} />
					) : (
						<Redirect to="/" />
					)
				) : (
					<Loading />
				)
			}
		/>
	)
}

export default RouteGuard
