import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Image, Form, Modal } from 'react-bootstrap'
import BarcodeReader from 'react-barcode-reader'
import { BiImport } from 'react-icons/bi'

import { getSiteItem } from '../Actions/importActions'
import { SHOW_SITE_RESET } from '../Constants/importConstants'

import Logo from '../images/logo.png'
import Header from '../Components/Header'
import Loading from '../Components/Loading'

const ShowItemOnSite = ({ history }) => {
	const [sku, setSku] = useState('')
	const [show, setShow] = useState(false)

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const showSiteItems = useSelector((state) => state.showSiteItems)
	const { loading, showSiteItem, showSiteItemError } = showSiteItems

	useEffect(() => {
		setShow(false)
		if (!userInfo) {
			history.push('/')
		}
		if (showSiteItem && showSiteItem !== 'No') {
			setShow(true)
		}

		if (showSiteItem && showSiteItem === 'No') {
			setShow(false)
			toast.error('Not Found!', { autoClose: 2000 })
		}

		if (showSiteItemError) {
			toast.error(showSiteItemError)
			dispatch({ type: SHOW_SITE_RESET })
		}
	}, [userInfo, showSiteItem, showSiteItemError, history, dispatch])

	const importBarcode = async (data) => {
		if (!sku) {
			await runCheck(data)
		} else {
			await runCheck(sku.toUpperCase())
			setSku('')
		}
	}

	const handleClose = () => {
		setShow(false)
		dispatch({ type: SHOW_SITE_RESET })
	}

	const runCheck = async (barcode) => {
		await dispatch(getSiteItem(userInfo.userToken, userInfo.apiToken, barcode))
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading ? (
					<Loading />
				) : (
					<>
						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>
									{showSiteItem ? showSiteItem.name : 'Undefined'}
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<img
									className="d-block w-100"
									src={showSiteItem ? showSiteItem.image1 : '#'}
									alt="#"
								/>
							</Modal.Body>
							<Modal.Footer>
								<Link
									to="#"
									className="btn btn-primary float-right"
									onClick={handleClose}
								>
									Close
								</Link>
							</Modal.Footer>
						</Modal>
						<Row style={{ justifyContent: 'center' }}>
							<Card style={{ width: '80%' }}>
								<Card.Body>
									<Card.Title style={{ textAlign: 'center' }}>
										<h1>
											<BiImport style={{ marginRight: 10 }} />
											Show Item from Website
										</h1>
									</Card.Title>
									<span className="input-group-text" style={{ marginTop: 75 }}>
										Click/Tap Here to start scanning...
									</span>
									<BarcodeReader onScan={importBarcode} />

									<Form style={{ paddingTop: 10 }}>
										<Form.Group controlId="skuForm">
											<Form.Control
												type="string"
												placeholder="Enter Product SKU"
												onChange={(e) => setSku(e.target.value)}
											/>
										</Form.Group>

										<Link
											to="#"
											className="btn btn-primary"
											onClick={importBarcode}
										>
											Check For Item
										</Link>
									</Form>
								</Card.Body>
							</Card>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default ShowItemOnSite
