import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

//Import Reducers

import {
	userLoginReducer,
	papaLoginReducer,
	registerUserReducer,
} from './Reducers/userReducers'
import {
	checkItemReducer,
	importItemReducer,
	checkSiteItemReducer,
	showItemSiteReducer,
} from './Reducers/importReducers'
import { removeItemReducer } from './Reducers/removeReducers'
import {
	adminGetUsersReducer,
	adminDeleteUserReducer,
	adminGetUserReducer,
	adminEditUserReducer,
} from './Reducers/adminReducers'

const reducer = combineReducers({
	userLogin: userLoginReducer,
	papaLogin: papaLoginReducer,
	importItems: importItemReducer,
	checkItems: checkItemReducer,
	checkSiteItems: checkSiteItemReducer,
	showSiteItems: showItemSiteReducer,
	removeItems: removeItemReducer,
	registerUser: registerUserReducer,
	adminUserList: adminGetUsersReducer,
	deleteUser: adminDeleteUserReducer,
	userDetails: adminGetUserReducer,
	editUser: adminEditUserReducer,
})

const userInfoFromStoreage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const papaInfoFromStoreage = localStorage.getItem('papaInfo')
	? JSON.parse(localStorage.getItem('papaInfo'))
	: null

const initialState = {
	userLogin: { userInfo: userInfoFromStoreage },
	papaLogin: { papaInfo: papaInfoFromStoreage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
