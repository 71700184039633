export const IMPORT_REQUEST = 'IMPORT_REQUEST'
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS'
export const IMPORT_FAIL = 'IMPORT_FAIL'
export const IMPORT_RESET = 'IMPORT_RESET'
export const CHECK_REQUEST = 'CHECK_REQUEST'
export const CHECK_SUCCESS = 'CHECK_SUCCESS'
export const CHECK_FAIL = 'CHECK_FAIL'
export const CHECK_RESET = 'CHECK_RESET'
export const CHECK_SITE_REQUEST = 'CHECK_SITE_REQUEST'
export const CHECK_SITE_SUCCESS = 'CHECK_SITE_SUCCESS'
export const CHECK_SITE_FAIL = 'CHECK_SITE_FAIL'
export const CHECK_SITE_RESET = 'CHECK_SITE_RESET'
export const SHOW_SITE_REQUEST = 'SHOW_SITE_REQUEST'
export const SHOW_SITE_SUCCESS = 'SHOW_SITE_SUCCESS'
export const SHOW_SITE_FAIL = 'SHOW_SITE_FAIL'
export const SHOW_SITE_RESET = 'SHOW_SITE_RESET'
