import React from 'react'
import { Spinner, Row } from 'react-bootstrap'

const Loading = () => {
	return (
		<>
			<Row style={{ justifyContent: 'center' }}>
				<Spinner animation="border" variant="primary" />
			</Row>
			<Row style={{ justifyContent: 'center', paddingTop: 50 }}>
				<h1>Loading...</h1>
			</Row>
		</>
	)
}

export default Loading
