import axios from 'axios'
import {
	ADMIN_DELETE_USER_FAIL,
	ADMIN_DELETE_USER_REQUEST,
	ADMIN_DELETE_USER_SUCCESS,
	ADMIN_EDIT_USER_FAIL,
	ADMIN_EDIT_USER_REQUEST,
	ADMIN_EDIT_USER_SUCCESS,
	ADMIN_GET_USER_FAIL,
	ADMIN_GET_USER_REQUEST,
	ADMIN_GET_USER_SUCCESS,
	ADMIN_LIST_USERS_FAIL,
	ADMIN_LIST_USERS_REQUEST,
	ADMIN_LIST_USERS_SUCCESS,
} from '../Constants/adminConstants'

export const adminListUsers = (userToken) => async (dispatch) => {
	try {
		dispatch({
			type: ADMIN_LIST_USERS_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		}

		const { data } = await axios.get('/api/admin/users/', config)

		dispatch({
			type: ADMIN_LIST_USERS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ADMIN_LIST_USERS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const adminDeleteUser = (userToken, id) => async (dispatch) => {
	try {
		dispatch({
			type: ADMIN_DELETE_USER_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		}

		const { data } = await axios.post(
			'/api/admin/users/delete',
			{
				id,
			},
			config
		)

		dispatch({
			type: ADMIN_DELETE_USER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ADMIN_DELETE_USER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

/**======================
 *    Admin Edit User Action
 *========================**/

export const adminEditUser = (userToken, user) => async (dispatch) => {
	try {
		dispatch({
			type: ADMIN_EDIT_USER_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		}

		const { data } = await axios.post(`/api/admin/users/edit/`, user, config)

		dispatch({
			type: ADMIN_EDIT_USER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ADMIN_EDIT_USER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

/**======================
 *    Admin Get User Details
 *========================**/

export const adminGetUser = (userToken, id) => async (dispatch) => {
	try {
		dispatch({
			type: ADMIN_GET_USER_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		}

		const { data } = await axios.post('/api/admin/user/', { id: id }, config)

		dispatch({
			type: ADMIN_GET_USER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: ADMIN_GET_USER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
