import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Button, Image, Form } from 'react-bootstrap'
import { FaSignInAlt } from 'react-icons/fa'
import { loginUser } from '../Actions/userActions'

import Logo from '../images/logo.png'
import Loading from '../Components/Loading'

const Login = ({ history }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	useEffect(() => {
		if (userInfo) {
			history.push('/dashboard')
		}

		if (error) {
			toast.error(error)
		}
	}, [history, userInfo, error])

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log('Login')
		console.log({ User: email, Password: password })
		dispatch(loginUser(email, password))
	}

	return (
		<Container>
			<ToastContainer />
			<Row style={{ justifyContent: 'center' }}>
				<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
			</Row>
			{loading ? (
				<Loading />
			) : (
				<Row style={{ justifyContent: 'center' }}>
					<Card style={{ width: '80%' }}>
						<Card.Body>
							<Card.Title style={{ textAlign: 'center' }}>
								<h1>
									<FaSignInAlt style={{ marginRight: 10 }} />
									Login
								</h1>
							</Card.Title>

							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Form.Group>

								<Form.Group controlId="formBasicPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicCheckbox"></Form.Group>
								<Button variant="primary" type="submit">
									Submit
								</Button>
							</Form>
							<p style={{ paddingTop: 10, textAlign: 'center' }}>
								Importer Version: 2.6.8
							</p>
						</Card.Body>
					</Card>
				</Row>
			)}
		</Container>
	)
}

export default Login
