import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Card, Button, Row, Image, Table } from 'react-bootstrap'
import { FaUsers } from 'react-icons/fa'
import { adminListUsers } from '../Actions/adminActions'
import { adminDeleteUser } from '../Actions/adminActions'
import { logoutUser } from '../Actions/userActions'

import {
	ADMIN_DELETE_USER_RESET,
	ADMIN_EDIT_USER_RESET,
	ADMIN_LIST_USERS_RESET,
} from '../Constants/adminConstants'

//Bring in components
import Logo from '../images/logo.png'
import Header from '../Components/Header'
import Loading from '../Components/Loading'
import ChangePass from '../Components/ChangePass'

/**========================================================================
 *                           Manage Users Component
 *========================================================================**/

const ManageUsers = ({ history }) => {
	const [show, setShow] = useState(false)
	const [chgUserPass, setChgUserPass] = useState()

	const dispatch = useDispatch()

	const adminUserList = useSelector((state) => state.adminUserList)
	const { loading, error, userList } = adminUserList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const deleteUser = useSelector((state) => state.deleteUser)
	const { deleteMessage, deleteError, loading: deleteLoading } = deleteUser

	const editUser = useSelector((state) => state.editUser)
	const { loading: editLoading, editMessage, editError } = editUser

	useEffect(() => {
		if (!userList) {
			dispatch(adminListUsers(userInfo.userToken))
		}

		if (error) {
			dispatch(logoutUser())
			history.push('/')
			toast.error(error)
		}

		if (deleteError) {
			toast.error(deleteError)
			dispatch({ type: ADMIN_DELETE_USER_RESET })
			dispatch({ type: ADMIN_LIST_USERS_RESET })
		}

		if (deleteMessage) {
			toast.success(deleteMessage.message)
			dispatch({ type: ADMIN_DELETE_USER_RESET })
			dispatch({ type: ADMIN_LIST_USERS_RESET })
		}

		if (editError) {
			toast.error(editError)
			dispatch({ type: ADMIN_EDIT_USER_RESET })
		}

		if (editMessage) {
			toast.success(editMessage.message)
			dispatch({ type: ADMIN_EDIT_USER_RESET })
		}
	}, [
		dispatch,
		userInfo,
		userList,
		error,
		deleteError,
		deleteMessage,
		history,
		editError,
		editMessage,
	])

	const handleDelete = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(adminDeleteUser(userInfo.userToken, id))
		}
	}

	const handleShow = (user) => {
		setShow(true)
		setChgUserPass(user)
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading || deleteLoading || editLoading ? (
					<Loading />
				) : (
					<Row style={{ justifyContent: 'center' }}>
						<Card style={{ width: '100%' }}>
							<Card.Title style={{ textAlign: 'center' }}>
								<h1>
									<FaUsers style={{ marginRight: 10 }} />
									Manage Users
								</h1>
							</Card.Title>
						</Card>
						<Table striped bordered hover responsive className="table-sm">
							<thead>
								<tr>
									<th className="text-center">Name</th>
									<th className="text-center">Email</th>
									<th className="text-center">User Service</th>
									<th className="text-center">API URL</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{userList &&
									userList.map((user) => (
										<tr key={user._id}>
											<td style={{ padding: 10 }}>{user.name}</td>
											<td style={{ padding: 10 }}>{user.email}</td>
											<td style={{ padding: 10 }}>{user.userService}</td>
											<td style={{ padding: 10 }}>{user.apiURL}</td>
											<td style={{ padding: 10 }}>
												<Link
													to={`/admin/edit-user/${user._id}`}
													className="btn btn-primary mx-1"
												>
													Edit User
												</Link>
												<Button
													type="submit"
													className="btn btn-info mx-1"
													onClick={() => handleShow(user)}
												>
													Change Password
												</Button>
												<Button
													type="submit"
													className="btn btn-danger mx-1"
													onClick={() => handleDelete(user._id)}
												>
													Delete User
												</Button>
												<ChangePass
													show={show}
													onHide={() => setShow(false)}
													user={chgUserPass}
													token={userInfo.userToken}
												/>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</Row>
				)}
			</Container>
		</>
	)
}

export default ManageUsers
