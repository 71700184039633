import {
	IMPORT_FAIL,
	IMPORT_REQUEST,
	IMPORT_RESET,
	IMPORT_SUCCESS,
	CHECK_FAIL,
	CHECK_REQUEST,
	CHECK_RESET,
	CHECK_SUCCESS,
	CHECK_SITE_FAIL,
	CHECK_SITE_REQUEST,
	CHECK_SITE_RESET,
	CHECK_SITE_SUCCESS,
	SHOW_SITE_REQUEST,
	SHOW_SITE_SUCCESS,
	SHOW_SITE_FAIL,
	SHOW_SITE_RESET,
} from '../Constants/importConstants'

export const importItemReducer = (state = {}, action) => {
	switch (action.type) {
		case IMPORT_REQUEST:
			return { loading: true }
		case IMPORT_SUCCESS:
			return { loading: false, importMessage: action.payload }
		case IMPORT_FAIL:
			return { loading: false, importError: action.payload }
		case IMPORT_RESET:
			return {}
		default:
			return state
	}
}

export const checkItemReducer = (state = {}, action) => {
	switch (action.type) {
		case CHECK_REQUEST:
			return { loading: true }
		case CHECK_SUCCESS:
			return { loading: false, checkData: action.payload }
		case CHECK_FAIL:
			return { loading: false, checkError: action.payload }
		case CHECK_RESET:
			return {}
		default:
			return state
	}
}

export const checkSiteItemReducer = (state = {}, action) => {
	switch (action.type) {
		case CHECK_SITE_REQUEST:
			return { loading: true }
		case CHECK_SITE_SUCCESS:
			return { loading: false, checkSite: action.payload || null }
		case CHECK_SITE_FAIL:
			return { loading: false, checkSiteError: action.payload }
		case CHECK_SITE_RESET:
			return {}
		default:
			return state
	}
}

export const showItemSiteReducer = (state = {}, action) => {
	switch (action.type) {
		case SHOW_SITE_REQUEST:
			return { loading: true }
		case SHOW_SITE_SUCCESS:
			return { loading: false, showSiteItem: action.payload }
		case SHOW_SITE_FAIL:
			return { loading: false, showSiteItemError: action.payload }
		case SHOW_SITE_RESET:
			return {}
		default:
			return state
	}
}
