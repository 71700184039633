import {
	REMOVE_FAIL,
	REMOVE_REQUEST,
	REMOVE_RESET,
	REMOVE_SUCCESS,
} from '../Constants/removeConstants'

export const removeItemReducer = (state = {}, action) => {
	switch (action.type) {
		case REMOVE_REQUEST:
			return { loading: true }
		case REMOVE_SUCCESS:
			return { loading: false, removeMessage: action.payload }
		case REMOVE_FAIL:
			return { loading: false, removeError: action.payload }
		case REMOVE_RESET:
			return {}
		default:
			return state
	}
}
