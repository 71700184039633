import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Form, Image, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FaUserEdit } from 'react-icons/fa'

import Logo from '../images/logo.png'
import Loading from '../Components/Loading'
import Header from '../Components/Header'
import { adminEditUser, adminGetUser } from '../Actions/adminActions'
import { ADMIN_EDIT_USER_RESET } from '../Constants/adminConstants'

const EditUser = ({ match, history }) => {
	const userID = match.params.id

	const dispatch = useDispatch()

	const userDetails = useSelector((state) => state.userDetails)
	const { loading, error, userData } = userDetails

	const editUser = useSelector((state) => state.editUser)
	const { loading: editLoading, editMessage, editError } = editUser

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [isAdmin, setIsAdmin] = useState('')
	const [userService, setUserService] = useState('')
	const [apiURL, setApiURL] = useState('')
	const [userKey, setUserKey] = useState('')
	const [authKey, setAuthKey] = useState('')
	const [apiType, setApiType] = useState('')
	const [facebookPage, setFacebookPage] = useState('')

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (!userData) {
			dispatch(adminGetUser(userInfo.userToken, userID))
		}

		if (userData && !loading) {
			setName(userData.name)
			setEmail(userData.email)
			setIsAdmin(userData.isAdmin)
			setUserService(userData.userService)
			setApiURL(userData.apiURL)
			setUserKey(userData.userKey)
			setAuthKey(userData.authKey)
			setApiType(userData.apiType)
			setFacebookPage(userData.facebookPage)
		}

		if (error) {
			toast.error(error)
		}

		if (editError) {
			toast.error(editError.error)
			dispatch({ type: ADMIN_EDIT_USER_RESET })
		}

		if (editMessage) {
			toast.success(editMessage.message)
			dispatch({ type: ADMIN_EDIT_USER_RESET })
		}
	}, [
		history,
		dispatch,
		userInfo,
		editMessage,
		editError,
		error,
		userData,
		userID,
		loading,
	])

	const handleEdit = (e) => {
		e.preventDefault()
		dispatch(
			adminEditUser(userInfo.userToken, {
				name,
				email,
				isAdmin,
				userService,
				userKey,
				authKey,
				apiURL,
				apiType,
				facebookPage,
			})
		)
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading || editLoading || !userService || !apiType ? (
					<Loading />
				) : (
					<>
						<Row style={{ justifyContent: 'center' }}>
							<Card style={{ width: '80%' }}>
								<Card.Body>
									<Card.Title style={{ textAlign: 'center' }}>
										<h1>
											<FaUserEdit style={{ marginRight: 10 }} />
											Edit User
										</h1>
									</Card.Title>

									<Form style={{ paddingTop: 10 }} onSubmit={handleEdit}>
										<Form.Group controlId="frmName">
											<Form.Label>Full Name</Form.Label>
											<Form.Control
												type="string"
												defaultValue={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmEmail">
											<Form.Label>Eamil Address</Form.Label>
											<Form.Control
												type="email"
												defaultValue={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmIsAdmin">
											<Form.Label>Is User an Admin?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={isAdmin}
												onChange={(e) => setIsAdmin(e.target.value)}
											>
												<option value="true">True</option>
												<option value="false">False</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmUserService">
											<Form.Label>Service?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={userService}
												onChange={(e) => setUserService(e.target.value)}
											>
												<option value="none">None</option>
												<option value="Paparazzi">Paparazzi</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmApiType">
											<Form.Label>API Type?</Form.Label>
											<Form.Control
												as="select"
												defaultValue={apiType}
												onChange={(e) => setApiType(e.target.value)}
											>
												<option value="none">None</option>
												<option value="woocommerce">WooCommerce</option>
												<option value="YUP">YUP! Commerce</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="frmApiURL">
											<Form.Label>API URL?</Form.Label>
											<Form.Control
												type="string"
												defaultValue={apiURL}
												onChange={(e) => setApiURL(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmApiURL">
											<Form.Label>Facebook Page</Form.Label>
											<Form.Control
												type="string"
												defaultValue={facebookPage}
												onChange={(e) => setFacebookPage(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmUserKey">
											<Form.Label>API User Key</Form.Label>
											<Form.Control
												type="string"
												defaultValue={userKey}
												onChange={(e) => setUserKey(e.target.value)}
											/>
										</Form.Group>
										<Form.Group controlId="frmAuthKey">
											<Form.Label>API Authentication Key</Form.Label>
											<Form.Control
												type="string"
												defaultValue={authKey}
												onChange={(e) => setAuthKey(e.target.value)}
											/>
										</Form.Group>

										<Button variant="primary" type="submit" className="mb-2">
											Save User
										</Button>
										<br></br>
										<Link to="/">Return to Dashboard</Link>
									</Form>
								</Card.Body>
							</Card>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default EditUser
