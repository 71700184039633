import axios from 'axios'
import {
	REMOVE_FAIL,
	REMOVE_REQUEST,
	REMOVE_SUCCESS,
} from '../Constants/removeConstants'

export const removeItem =
	(userToken, productSKU, apiToken) => async (dispatch) => {
		try {
			dispatch({
				type: REMOVE_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/remove',
				{
					sku: productSKU,
					apiToken: apiToken,
				},
				config
			)

			dispatch({
				type: REMOVE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: REMOVE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
