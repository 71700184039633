import {
	PAPA_LOGIN_FAIL,
	PAPA_LOGIN_REQUEST,
	PAPA_LOGIN_SUCCESS,
	PAPA_LOGOUT,
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAIL,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_RESET,
} from '../Constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true, products: [] }
		case USER_LOGIN_SUCCESS:
			return { loading: false, userInfo: action.payload }
		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload }
		case USER_LOGOUT:
			return {}
		default:
			return state
	}
}

export const papaLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case PAPA_LOGIN_REQUEST:
			return { loading: true, products: [] }
		case PAPA_LOGIN_SUCCESS:
			return { loading: false, papaInfo: action.payload }
		case PAPA_LOGIN_FAIL:
			return { loading: false, error: action.payload }
		case PAPA_LOGOUT:
			return {}
		default:
			return state
	}
}

export const registerUserReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTER_REQUEST:
			return { loading: true }
		case USER_REGISTER_SUCCESS:
			return { loading: false, registerMessage: action.payload }
		case USER_REGISTER_FAIL:
			return { loading: false, registerError: action.payload }
		case USER_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
