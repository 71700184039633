import {
	ADMIN_DELETE_USER_FAIL,
	ADMIN_DELETE_USER_REQUEST,
	ADMIN_DELETE_USER_RESET,
	ADMIN_DELETE_USER_SUCCESS,
	ADMIN_EDIT_USER_FAIL,
	ADMIN_EDIT_USER_REQUEST,
	ADMIN_EDIT_USER_RESET,
	ADMIN_EDIT_USER_SUCCESS,
	ADMIN_GET_USER_FAIL,
	ADMIN_GET_USER_REQUEST,
	ADMIN_GET_USER_RESET,
	ADMIN_GET_USER_SUCCESS,
	ADMIN_LIST_USERS_FAIL,
	ADMIN_LIST_USERS_REQUEST,
	ADMIN_LIST_USERS_RESET,
	ADMIN_LIST_USERS_SUCCESS,
} from '../Constants/adminConstants'

export const adminGetUsersReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMIN_LIST_USERS_REQUEST:
			return { loading: true, userList: [] }
		case ADMIN_LIST_USERS_SUCCESS:
			return { loading: false, userList: action.payload }
		case ADMIN_LIST_USERS_FAIL:
			return { loading: false, error: action.payload }
		case ADMIN_LIST_USERS_RESET:
			return {}
		default:
			return state
	}
}

export const adminDeleteUserReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMIN_DELETE_USER_REQUEST:
			return { loading: true }
		case ADMIN_DELETE_USER_SUCCESS:
			return { loading: false, deleteMessage: action.payload }
		case ADMIN_DELETE_USER_FAIL:
			return { loading: false, deleteError: action.payload }
		case ADMIN_DELETE_USER_RESET:
			return {}
		default:
			return state
	}
}

export const adminEditUserReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMIN_EDIT_USER_REQUEST:
			return { loading: true }
		case ADMIN_EDIT_USER_SUCCESS:
			return { loading: false, editMessage: action.payload }
		case ADMIN_EDIT_USER_FAIL:
			return { loading: false, editError: action.payload }
		case ADMIN_EDIT_USER_RESET:
			return {}
		default:
			return state
	}
}

export const adminGetUserReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMIN_GET_USER_REQUEST:
			return { loading: true }
		case ADMIN_GET_USER_SUCCESS:
			return { loading: false, userData: action.payload }
		case ADMIN_GET_USER_FAIL:
			return { loading: false, error: action.payload }
		case ADMIN_GET_USER_RESET:
			return {}
		default:
			return state
	}
}
