import React from 'react'
import { Spinner, Row } from 'react-bootstrap'

const ImportLoading = () => {
	return (
		<div
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.79)',
				position: 'absolute',
				minWidth: '100%',
				minHeight: '100%',
				zIndex: '1',
			}}
		>
			<Row
				style={{
					justifyContent: 'center',
					minWidth: '100%',
					minHeight: '100%',
					position: 'absolute',
					alignItems: 'center',
				}}
			>
				<Spinner animation="border" variant="primary" />
			</Row>
			<Row
				style={{
					justifyContent: 'center',
					minWidth: '100%',
					minHeight: '100%',
					position: 'absolute',
					alignItems: 'center',
					paddingTop: 100,
				}}
			>
				<h2 style={{ color: 'white' }}>Loading...</h2>
			</Row>
		</div>
	)
}

export default ImportLoading
