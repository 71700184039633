import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Image, Form } from 'react-bootstrap'
import BarcodeReader from 'react-barcode-reader'
import { TiDelete } from 'react-icons/ti'

import { removeItem } from '../Actions/removeActions'
import { REMOVE_RESET } from '../Constants/removeConstants'

import Header from '../Components/Header'
import Logo from '../images/logo.png'
import Loading from '../Components/Loading'

const RemoveItems = ({ history }) => {
	const [sku, setSku] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const removeItems = useSelector((state) => state.removeItems)
	const { loading, removeMessage, removeError } = removeItems

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (removeMessage) {
			toast.success(removeMessage.message)
			dispatch({ type: REMOVE_RESET })
		}

		if (removeError) {
			console.log(removeError)
			toast.error(removeError)
			dispatch({ type: REMOVE_RESET })
		}
	}, [userInfo, removeMessage, removeError, history, dispatch])

	const removeBarcode = async (data) => {
		if (!sku) {
			console.log({ Barcode: data })
			await runRemove(data)
		} else {
			console.log({ Form: sku })
			await runRemove(sku.toUpperCase())
			setSku('')
		}
	}

	const runRemove = async (barcode) => {
		await dispatch(removeItem(userInfo.userToken, barcode, userInfo.apiToken))
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading ? (
					<Loading />
				) : (
					<>
						<Row style={{ justifyContent: 'center' }}>
							<Card style={{ width: '80%' }}>
								<Card.Body>
									<Card.Title style={{ textAlign: 'center' }}>
										<h1>
											<TiDelete style={{ marginRight: 10 }} />
											Remove Items
										</h1>
									</Card.Title>
									<span className="input-group-text" style={{ marginTop: 75 }}>
										Click/Tap Here to start scanning...
									</span>
									<BarcodeReader onScan={removeBarcode} />

									<Form style={{ paddingTop: 10 }}>
										<Form.Group controlId="skuForm">
											<Form.Control
												type="string"
												placeholder="Enter Product SKU"
												onChange={(e) => setSku(e.target.value)}
											/>
										</Form.Group>

										<Link
											to="#"
											className="btn btn-primary"
											onClick={removeBarcode}
										>
											Remove Item
										</Link>
									</Form>
								</Card.Body>
							</Card>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default RemoveItems
