import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Card, Image, Form } from 'react-bootstrap'
import BarcodeReader from 'react-barcode-reader'
import { BiImport } from 'react-icons/bi'

import { checkSiteItem } from '../Actions/importActions'
import { CHECK_SITE_RESET } from '../Constants/importConstants'

import Logo from '../images/logo.png'
import Header from '../Components/Header'
import Loading from '../Components/Loading'

const CheckSiteItems = ({ history }) => {
	const [sku, setSku] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const checkSiteItems = useSelector((state) => state.checkSiteItems)
	const { loading, checkSite, checkSiteError } = checkSiteItems

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (checkSite && checkSite === 'Yes') {
			toast.success('Yes', { autoClose: 2000 })
		}

		if (checkSite && checkSite === 'No') {
			toast.error('No', { autoClose: 2000 })
		}

		if (checkSiteError) {
			toast.error(checkSiteError)
			dispatch({ type: CHECK_SITE_RESET })
		}
	}, [userInfo, checkSite, checkSiteError, history, dispatch])

	const importBarcode = async (data) => {
		if (!sku) {
			await runCheck(data)
		} else {
			await runCheck(sku.toUpperCase())
			setSku('')
		}
	}

	const runCheck = async (barcode) => {
		await dispatch(
			checkSiteItem(userInfo.userToken, userInfo.apiToken, barcode)
		)
	}

	return (
		<>
			<Header />
			<Container>
				<ToastContainer />
				<Row style={{ justifyContent: 'center' }}>
					<Image style={{ paddingTop: 50, paddingBottom: 50 }} src={Logo} />
				</Row>
				{loading ? (
					<Loading />
				) : (
					<>
						<Row style={{ justifyContent: 'center' }}>
							<Card style={{ width: '80%' }}>
								<Card.Body>
									<Card.Title style={{ textAlign: 'center' }}>
										<h1>
											<BiImport style={{ marginRight: 10 }} />
											Check If Item is on Website
										</h1>
									</Card.Title>
									<span className="input-group-text" style={{ marginTop: 75 }}>
										Click/Tap Here to start scanning...
									</span>
									<BarcodeReader onScan={importBarcode} />

									<Form style={{ paddingTop: 10 }}>
										<Form.Group controlId="skuForm">
											<Form.Control
												type="string"
												placeholder="Enter Product SKU"
												onChange={(e) => setSku(e.target.value)}
											/>
										</Form.Group>

										<Link
											to="#"
											className="btn btn-primary"
											onClick={importBarcode}
										>
											Check For Item On Site
										</Link>
									</Form>
								</Card.Body>
							</Card>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default CheckSiteItems
