import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

//Bring in components
import { logoutUser } from '../Actions/userActions'

/**=======================================================================================================================
 *!                                                   Header Component
 *=======================================================================================================================**/

const Header = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, userInfo } = userLogin

	const handleLogout = () => {
		console.log('logout')
		dispatch(logoutUser())
	}
	return (
		<>
			<Navbar bg="primary" variant="dark" expand="lg">
				<Navbar.Brand href="/" style={{ paddingLeft: 75 }}>
					YUP! Import
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto" style={{ paddingRight: 75 }}>
						<Nav.Link href="/dashboard">Dashboard</Nav.Link>
						<NavDropdown title="Tools" id="collasible-nav-dropdown">
							<NavDropdown.Item href="/import-items">
								Import Items
							</NavDropdown.Item>
							<NavDropdown.Item href="/remove-items">
								Remove Items
							</NavDropdown.Item>
							<NavDropdown.Item href="/check-items">
								Check Item Tags From Paparazzi
							</NavDropdown.Item>
							<NavDropdown.Item href="/check-site-items">
								Check If Items on Website (Beta)
							</NavDropdown.Item>
							<NavDropdown.Item href="/show-site-item">
								Show Item From Website (Beta)
							</NavDropdown.Item>
							{userInfo && userInfo.isAdmin && (
								<>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/import-csv">
										Import from CSV (Beta)
									</NavDropdown.Item>
								</>
							)}
						</NavDropdown>
						{!loading && userInfo && userInfo.isAdmin && (
							<NavDropdown title="Admin Tools" id="collasible-nav-dropdown">
								<NavDropdown.Item href="/admin/register">
									Add Users
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/manage-users">
									Manage Users
								</NavDropdown.Item>
							</NavDropdown>
						)}
						<Nav.Link href="#" onClick={handleLogout}>
							Logout
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	)
}

export default Header
