import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//Bring in components
import Loading from '../Components/Loading'
import Header from '../Components/Header'
import { Card, Row } from 'react-bootstrap'

const Dashboard = ({ history }) => {
	const [user, setUser] = useState('')

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	useEffect(() => {
		if (!userInfo) {
			history.push('/')
		}

		if (error) {
			console.log(error)
		}

		if (userInfo) {
			setUser(userInfo.user)
		}
	}, [userInfo, error, history])

	return (
		<>
			{loading && <Loading />}
			<Header />
			<Row style={{ justifyContent: 'center', paddingTop: 75 }}>
				<Card style={{ width: '40%' }}>
					<Card.Title style={{ textAlign: 'center' }}>
						<h1>Dashboard</h1>
					</Card.Title>
					<Card.Body>Welcome {user}</Card.Body>
				</Card>
			</Row>
		</>
	)
}

export default Dashboard
