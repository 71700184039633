import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import Header from '../Components/Header'
import PapaLoginPrompt from '../Components/PapaLoginPrompt'
import ItemChecker from '../Components/ItemChecker'

const CheckItems = ({ history }) => {
	const papaLogin = useSelector((state) => state.papaLogin)
	const { papaInfo } = papaLogin

	return (
		<>
			<Header />
			<Container>
				<Row style={{ justifyContent: 'center' }}>
					{!papaInfo ? (
						<PapaLoginPrompt history={history} />
					) : (
						<ItemChecker history={history} />
					)}
				</Row>
			</Container>
		</>
	)
}

export default CheckItems
