import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { adminEditUser } from '../Actions/adminActions'

const ChangePass = ({ onHide, user, show, token }) => {
	const [password, setPassword] = useState()
	const [password2, setPassword2] = useState()

	const dispatch = useDispatch()

	useEffect(() => {
		if (!user) {
			onHide()
		}
	}, [onHide, user])

	const handleClose = () => {
		onHide = false
	}

	const handlePasswordChange = (e) => {
		e.preventDefault()

		dispatch(
			adminEditUser(token, {
				email: user.email,
				password: password,
				password2: password2,
			})
		)
		onHide()
	}
	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Change Password For {user && user.name}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handlePasswordChange} style={{ padding: 25 }}>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Password"
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="formBasicPassword2">
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Confirm Password"
							onChange={(e) => setPassword2(e.target.value)}
						/>
					</Form.Group>
					<Button
						variant="secondary"
						onClick={onHide}
						style={{ float: 'right', margin: 5 }}
					>
						Close
					</Button>
					<Button
						variant="primary"
						type="submit"
						style={{ float: 'right', margin: 5 }}
					>
						Change Password
					</Button>
				</Form>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	)
}

export default ChangePass
