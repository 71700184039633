import axios from 'axios'

import {
	PAPA_LOGIN_FAIL,
	PAPA_LOGIN_REQUEST,
	PAPA_LOGIN_SUCCESS,
	PAPA_LOGOUT,
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAIL,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
} from '../Constants/userConstants'

export const loginUser = (email, password) => async (dispatch) => {
	try {
		dispatch({
			type: USER_LOGIN_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			'/api/users/login',
			{ email, password },
			config
		)

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		})

		localStorage.setItem('userInfo', JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const logoutUser = () => (dispatch) => {
	localStorage.removeItem('userInfo')
	localStorage.removeItem('papaInfo')
	dispatch({
		type: USER_LOGOUT,
	})
	dispatch({
		type: PAPA_LOGOUT,
	})
}

export const loginPapa = (papaID, password) => async (dispatch) => {
	try {
		dispatch({
			type: PAPA_LOGIN_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			'/api/users/papa-login',
			{ papaUser: papaID, papaPassword: password },
			config
		)

		dispatch({
			type: PAPA_LOGIN_SUCCESS,
			payload: data,
		})

		localStorage.setItem('papaInfo', JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: PAPA_LOGIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const logoutPapa = () => (dispatch) => {
	localStorage.removeItem('papaInfo')
	dispatch({
		type: PAPA_LOGOUT,
	})
}

export const addNewUser =
	(
		userToken,
		name,
		email,
		password,
		password2,
		isAdmin,
		userService,
		userKey,
		authKey,
		apiURL,
		apiType
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: USER_REGISTER_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/admin/users/register',
				{
					name,
					email,
					password,
					password2,
					isAdmin,
					userService,
					userKey,
					authKey,
					apiURL,
					apiType,
				},
				config
			)

			dispatch({
				type: USER_REGISTER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: USER_REGISTER_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
