import axios from 'axios'
import {
	IMPORT_FAIL,
	IMPORT_REQUEST,
	IMPORT_SUCCESS,
	CHECK_FAIL,
	CHECK_REQUEST,
	CHECK_SUCCESS,
	CHECK_SITE_FAIL,
	CHECK_SITE_REQUEST,
	CHECK_SITE_SUCCESS,
	SHOW_SITE_REQUEST,
	SHOW_SITE_SUCCESS,
	SHOW_SITE_FAIL,
} from '../Constants/importConstants'

export const importItem =
	(userToken, apiToken, productSlug, productSKU, papaSession, prodQuantity) =>
	async (dispatch) => {
		try {
			dispatch({
				type: IMPORT_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/import',
				{
					apiToken: apiToken,
					productSlug: productSlug,
					productSKU: productSKU,
					papaSession: papaSession,
					qty: prodQuantity,
				},
				config
			)

			dispatch({
				type: IMPORT_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: IMPORT_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const checkItem =
	(userToken, apiToken, productSlug, productSKU, papaSession, prodQuantity) =>
	async (dispatch) => {
		try {
			dispatch({
				type: CHECK_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/check',
				{
					apiToken: apiToken,
					productSlug: productSlug,
					productSKU: productSKU,
					papaSession: papaSession,
					qty: prodQuantity,
				},
				config
			)

			dispatch({
				type: CHECK_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: CHECK_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const checkSiteItem =
	(userToken, apiToken, productSKU) => async (dispatch) => {
		try {
			dispatch({
				type: CHECK_SITE_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/check/site',
				{
					apiToken: apiToken,
					productSKU: productSKU,
				},
				config
			)

			dispatch({
				type: CHECK_SITE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: CHECK_SITE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getSiteItem =
	(userToken, apiToken, productSKU) => async (dispatch) => {
		try {
			dispatch({
				type: SHOW_SITE_REQUEST,
			})

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userToken}`,
				},
			}

			const { data } = await axios.post(
				'/api/check/show-item',
				{
					apiToken: apiToken,
					productSKU: productSKU,
				},
				config
			)
			dispatch({
				type: SHOW_SITE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: SHOW_SITE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
